<template>
    <div class="uploadjob" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="back()">
                        <van-icon name="arrow-left" />
                    </div>
                </van-col>
                <van-col span="16">
                    {{lang.upload_job}}
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <van-row>
            <van-col offset="1" span="22">
                <div class="uploadJob file" v-for="file in uploadedList" :key="file">
                    <van-row>
                        <van-col offset="1" span="20">
                            <div class="fileName">{{file.title}}</div>
                        </van-col>
                        <van-col span="2">
                           <van-icon  size="20px" name="cross" @click="deleteUploadedFile(file)"/>
                        </van-col>
                    </van-row>
                </div>
                <div class="uploadJob">
                    <van-uploader 
                        max-count="1" 
                        accept="application"
                        compressed="false"
                        v-model="uploadFile"
                        :max-size="10 * 1024 * 1024"
                        @oversize="oversize"
                        :before-read="beforeRead"
                        :after-read="afterRead"
                        >
                        {{lang.select_file}}
                    </van-uploader>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import { reactive, toRefs ,ref } from 'vue';
import Common from "../../library/Common";
import { useRouter } from 'vue-router';
import request from '../../library/MyRequest';
import { Toast,Dialog } from 'vant';
import "@/css/form.css";
export default {
    name: 'uploadjob',
    setup(){
        const common = Common;
        common.chechLogin(router);
        const router = useRouter();
        const lesson_id = router.currentRoute.value.params.id;

        let state = reactive({
            lang:{
                langBtn:"",
                upload_job:"",
                select_file:"",
                submit:"",
                jobSize:"",
                justUploadjob:"",
                filenametoolong:"",
                DeleteThisJob:"",
                JobUploaded:"",
                uploading:"",
                timeout:"",
                networkError:"",
            },
            uploadedList:[],
        });
        const uploadFile = ref([]);

        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageBtn();
        }

        const back = function(){
            router.go(-1);
        }

        const getList = async()=>{
            var res = await request.get(`lesson/homework/?lesson_id=${lesson_id}`);
            if(res.status == 200){
                state.uploadedList = res.data.map((item)=>{
                    return {
                        id:item.id,
                        title:item.title,
                        desc:item.desc,
                        file_url:item.file_url
                    }
                });
            }
        }

        const oversize = function(){
            Toast(state.lang.justUploadjob);
        }

        const hasFile = function(filename){
            for(var file of state.uploadedList){
                if(file.title == filename){
                    return true;
                }
            }
            return false;
        }
        const beforeRead = (File) => {
            if(File.name.length>100){
                Toast(state.lang.filenametoolong);
                return false;
            }else if(hasFile(File.name)){
                Toast(state.lang.JobUploaded);
                return false; 
            }
            if(File.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || 
               File.type == "application/msword" || 
               File.type == "application/pdf"
               ){
                return true;
            }else{
                Toast(state.lang.justUploadjob);
                return false;
            }
        }

        const afterRead = async(event)=>{
            uploadFile.value.splice(0,1);
            Toast.loading({
                message:state.lang.uploading,
                forbidClick: true,
                overlay:true,
            });
            const file = event.file;
            const formData = new FormData();
            formData.append('file',file);
            try{
                var uploadRes = await request.upload(formData);
                if(uploadRes.status == 200){
                    var uploadfile = {
                        lesson_id:lesson_id,
                        title:file.name,
                        desc:"",
                        file_url:uploadRes.data.url
                    };
                    try{
                        var res = await request.post("lesson/homework/",uploadfile);
                        if(res.status == 201){
                            uploadfile.id = res.data.id;
                            state.uploadedList.push(uploadfile);
                        }
                    }catch(e){
                        console.log(e.message);
                    }  
                }
            }catch(e){
                Toast(e.message);
                console.log(e.message);
            }
            
            Toast.clear();
        }

        const deleteUploadedFile = function(item){
            Dialog.confirm({
                message:state.lang.DeleteThisJob,
                confirmButtonText:state.lang.yes,
                cancelButtonText:state.lang.no,
            })
            .then(async() => {
                var res = await request.del(`lesson/homework/${item.id}`);
                if(res.status == 204){
                    state.uploadedList.splice(state.uploadedList.indexOf(item),1);
                }
            });
        }

        if(!lesson_id){
            back();
        }else{
            getList();
        }

        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            back,
            uploadFile,
            oversize,
            beforeRead,
            afterRead,
            deleteUploadedFile
        };
    }
}
</script>

<style scoped>
.uploadJob{
    width: 100%;
    height: 100px;
    line-height: 100px;
    border:5px solid #4284FD;
    border-radius: 20px;
    color: #4284FD;
    font-size: 32px;
    font-weight: 600;
    margin-top:20px;

}

.file{
    border:5px solid green;
    color: green;
}

.fileName{
    width: 100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}

.foot{
    position:fixed;
    width: 100%;
    bottom:0px;
    background-color: #fff;
    font-size: 28px;
    padding:30px 0;
}
</style>